import get from 'lodash/get';
import Animation, { AnimationOptions, TimingFunction } from '../types/animation.type';
import Template from '../types/template.type';
import { MigrateFunction } from './types/migrateFunction.type';

interface AnimationEasingOld {
    color: TimingFunction;
    backgroundColor: TimingFunction;
    opacity: TimingFunction;
    position: TimingFunction;
    rotation: TimingFunction;
    scale: TimingFunction;
    shadow: TimingFunction;
    size: TimingFunction;
}

/**
 * 11-07-2023 - 17:00
 * Convert animationEasing to the individual keyframes.
 * @param template - The whole template.
 * @returns New template
 */
const convertKeyframeEasing: MigrateFunction = (template: Template): Template => {
    // Loop over layer properties.
    Object.keys(template.layerProperties).forEach((formatKey) => {
        const formatData = template.layerProperties[formatKey];

        // Loop over format properties.
        Object.keys(formatData).forEach((frameTypeKey) => {
            const frameData = formatData[frameTypeKey];

            // Loop over frame data.
            Object.keys(frameData).forEach((layerKey) => {
                const layerData = frameData[layerKey];

                // Check if there is still old animation easing data.
                if ('animations' in layerData) {
                    // Loop over animatons.
                    Object.keys(layerData.animations ?? {}).forEach((attribute) => {
                        const animationData = layerData.animations?.[attribute as AnimationOptions] as Animation[];
                        if (!animationData) return;

                        // Loop over animation data.
                        animationData.forEach((keyframe) => {
                            // If easing already exists, then the migration already ran before.
                            if (keyframe.easing) return;

                            const animationEasing: AnimationEasingOld | undefined = (() => {
                                // If animationEasing exist in the layer.
                                if ('animationEasing' in layerData) {
                                    return layerData.animationEasing as AnimationEasingOld;
                                }

                                // Otherwise get animationEasing from the general properties.
                                const generalAnimationEasing = get(template, `layerProperties.general.${frameTypeKey}.${layerKey}.animationEasing`);

                                if (generalAnimationEasing) {
                                    return generalAnimationEasing as AnimationEasingOld;
                                }
                            })();

                            keyframe.easing = {
                                type: 'linear',
                                value: 'linear'
                            };

                            if (!animationEasing || !animationEasing[attribute as AnimationOptions]) return;
                            keyframe.easing.type = animationEasing[attribute as AnimationOptions];
                            keyframe.easing.value = animationEasing[attribute as AnimationOptions];
                        });
                    });
                }
            });
        });
    });

    return template;
};

export default convertKeyframeEasing;
